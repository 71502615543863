import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'input'];
  declare contentTarget: HTMLDivElement;
  declare inputTarget: HTMLInputElement;

  connect(): void {
    this.trigger();
  }

  trigger(): void {
    this.contentTarget.hidden = !this.inputTarget.checked;
  }
}
