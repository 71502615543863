import { Controller } from '@hotwired/stimulus';
import { camera } from '@/lib/camera';

export default class extends Controller {
  static targets = ['canvas'];
  canvasTarget!: HTMLCanvasElement;

  async connect(): Promise<void> {
    // Necessary for firefox
    this.updateElementState('prompt');
    const state = await camera.permissionState();
    this.updateElementState(state);
    camera.addPermissionUpdateCallback(this.updateElementState.bind(this));
    if (state === 'granted') {
      this.requestVideo();
    }
  }

  disconnect(): void {
    camera.stopVideo();
  }

  updateElementState(permission: PermissionStatus['state'] | 'loading'): void {
    this.clearElementState();
    this.setElementState(permission);
  }

  clearElementState(): void {
    this.element.classList.remove('camera--initial-state');
    this.element.classList.remove('camera--ready-state');
    this.element.classList.remove('camera--denied-state');
    this.element.classList.remove('camera--loading-state');
  }

  setElementState(permission: PermissionStatus['state'] | 'loading'): void {
    switch (permission) {
      case 'prompt':
        this.element.classList.add('camera--initial-state');
        break;
      case 'granted':
        this.element.classList.add('camera--ready-state');
        break;
      case 'denied':
        this.element.classList.add('camera--denied-state');
        break;
      default:
        this.element.classList.add('camera--loading-state');
        break;
    }
  }

  async requestVideo(): Promise<void> {
    this.updateElementState('loading');
    try {
      await camera.requestVideo(this.canvasTarget);
    } catch (error) {
      this.updateElementState('denied');
    }
  }
}
