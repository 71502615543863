import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link'];
  static values = {
    path: String,
    mediaSelector: String,
    param: String,
  };

  linkTarget!: HTMLLinkElement;
  hasLinkTarget!: boolean;
  pathValue!: string;
  mediaSelectorValue!: string;
  paramValue!: string;

  connect(): void {
    this.updateDisplay();
  }

  openModal(): void {
    const value = this.element
      .querySelector(`input[name="${this.mediaSelectorValue}"]:checked`)
      ?.getAttribute('value');
    const path = this.pathValue;
    const param = this.paramValue;
    const url = `${path}/?${param}=${value}`;

    window.ui?.modal.open({
      url,
      id: '',
      size: '',
      close: true,
    });
  }

  updateDisplay(): void {
    if (!this.hasLinkTarget) return;
    const value = this.element
      .querySelector(`input[name="${this.mediaSelectorValue}"]:checked`)
      ?.getAttribute('value');
    this.linkTarget.classList.toggle('disabled', value === 'all');
  }
}
