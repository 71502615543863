import $ from 'jquery';
import 'slick-carousel';

import { Controller } from '@hotwired/stimulus';

class Base extends Controller {
  contentTarget!: HTMLElement;
}

export default class extends (Controller as typeof Base) {
  static targets = ['content'];

  nextSlide(): void {
    $(this.contentTarget).slick('slickNext');
  }

  restart(): void {
    $(this.contentTarget).slick('slickGoTo', 0);
  }

  connect(): void {
    $(this.contentTarget).slick({
      dots: false,
      arrows: false,
      speed: 1000,
      slidesToShow: 1,
      infinite: false,
      centerMode: true,
      centerPadding: 'calc(50vw - 302px - 24px)',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '18px',
            slidesToShow: 1,
          },
        },
      ],
    });
  }
}
