export class Camera {
  private onPermissionUpdateCallbacks: Array<(state: PermissionState) => void> =
    [];
  private canvas!: HTMLCanvasElement;
  private stream: MediaStream | null = null;

  async permissionState(): Promise<PermissionState> {
    // Use the fallback method for checking permission in Firefox
    if (
      typeof navigator.permissions?.query !== 'function' ||
      navigator.userAgent.includes('Firefox')
    ) {
      return new Promise((resolve) => {
        const videoElement = document.createElement('video');

        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            videoElement.srcObject = stream;
            videoElement.onloadedmetadata = () => {
              stream.getTracks().forEach((track) => track.stop());
              resolve('granted' as PermissionState);
            };
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Fallback permission check error:', error);
            resolve('denied' as PermissionState);
          });
      });
    }

    try {
      const result = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });
      return result.state;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Permission query error:', error);
      return 'prompt' as PermissionState;
    }
  }

  async requestVideo(canvas: HTMLCanvasElement): Promise<void> {
    this.canvas = canvas;
    this.stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment' },
    });
    const video = this.createVideoElement(this.stream);
    await video.play();
    const state = await this.permissionState();
    this.onPermissionUpdateCallbacks.forEach((callback) => callback(state));
    this.observeCanvasResize(canvas);
    this.render(canvas, video);
  }

  private createVideoElement(stream: MediaStream): HTMLVideoElement {
    const video = document.createElement('video');
    video.playsInline = true;
    video.srcObject = stream;
    return video;
  }

  private observeCanvasResize(canvas: HTMLCanvasElement): void {
    this.updateCanvasSize(canvas); // Update the canvas size immediately

    const resizeObserver = new ResizeObserver(() => {
      this.updateCanvasSize(canvas);
    });

    resizeObserver.observe(canvas);
  }

  private render(canvas: HTMLCanvasElement, video: HTMLVideoElement): void {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const renderFrame = () => {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const videoAspectRatio = video.videoWidth / video.videoHeight;

        let sourceX, sourceY, sourceWidth, sourceHeight;

        if (videoAspectRatio > 1) {
          // Video is wider than it is tall
          sourceWidth = video.videoHeight;
          sourceHeight = video.videoHeight;
          sourceX = (video.videoWidth - sourceWidth) / 2;
          sourceY = 0;
        } else {
          // Video is taller than it is wide
          sourceWidth = video.videoWidth;
          sourceHeight = video.videoWidth;
          sourceX = 0;
          sourceY = (video.videoHeight - sourceHeight) / 2;
        }

        ctx.drawImage(
          video,
          sourceX,
          sourceY,
          sourceWidth,
          sourceHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );
      }
      requestAnimationFrame(renderFrame);
    };
    requestAnimationFrame(renderFrame);
  }

  private updateCanvasSize(canvas: HTMLCanvasElement): void {
    // Make the canvas a square by setting the width and height to the smallest dimension of the canvas
    const minDimension = Math.min(canvas.clientWidth, canvas.clientHeight);

    canvas.width = minDimension;
    canvas.height = minDimension;
  }

  stopVideo(): void {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }
  }

  addPermissionUpdateCallback(
    callback: (state: PermissionState) => void
  ): void {
    this.onPermissionUpdateCallbacks.push(callback);
  }

  captureImage(): Promise<File> {
    return new Promise((resolve, reject) => {
      this.canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'image.jpg', { type: 'image/jpg' });
          resolve(file);
          this.stopVideo();
        } else {
          reject(new Error('Unable to capture image'));
        }
      }, 'image/jpg');
    });
  }
}

export const camera = new Camera();
