import { isDesktop } from '@/lib/viewport';
import { Controller } from '@hotwired/stimulus';

class Base extends Controller {
  public offsetValue!: string;
  public paddingValue?: string;
}

export default class extends (Controller as typeof Base) {
  static values = { offset: String, padding: String };
  private screenSize = 'desktop';

  resetTransform(): void {
    if (this.paddingValue) {
      this.element.removeAttribute('style');
    }
    this.element.classList.remove('out-of-viewport');
  }

  checkResize(): void {
    if (isDesktop()) {
      if (this.screenSize === 'mobile') {
        this.screenSize = 'desktop';
        this.resetTransform();
      }
    } else {
      if (this.screenSize === 'desktop') {
        this.screenSize = 'mobile';
        this.resetTransform();
      }
    }
  }

  connect(): void {
    const options = {
      rootMargin: `${this.offsetValue || '0px'} 0px 0px 0px`,
      threshold: 1.0,
    };

    window.addEventListener('resize', this.checkResize);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const treshold = Math.abs(entry.boundingClientRect.top) < 250;
        if (treshold) {
          if (!entry.isIntersecting) {
            if (this.paddingValue && isDesktop()) {
              this.element.setAttribute(
                'style',
                `padding-top: ${this.element.clientHeight}px`
              );
            }
            this.element.classList.add('out-of-viewport');
          } else {
            this.resetTransform();
          }
        }
      });
    }, options);

    observer.observe(this.element);
  }

  disconnect(): void {
    window.removeEventListener('resize', this.checkResize);
  }
}
