import { Controller } from '@hotwired/stimulus';

class Base extends Controller {
  public contentTarget!: HTMLDivElement;
  public scrollTopValue?: string;
}

export default class extends (Controller as typeof Base) {
  static values = { scrollTop: String };
  public static targets = ['content'];

  connect(): void {
    // min duration 0.2s, max duration 0.5s
    const transitionDuration = Math.max(
      0.2,
      Math.min(0.5, this.contentTarget.scrollHeight / 1000)
    );
    this.contentTarget.style.transitionDuration = `${transitionDuration}s`;
  }

  toggleExpanded(): void {
    const maxHeight = this.contentTarget.style.maxHeight;

    if (maxHeight && maxHeight !== '0px') {
      this.element.classList.remove('collapse-panel--expanded');
      this.contentTarget.style.maxHeight = '0';
      if (this.scrollTopValue) {
        window.scroll(0, 0);
      }
    } else {
      this.element.classList.add('collapse-panel--expanded');
      this.contentTarget.style.maxHeight = `${this.contentTarget.scrollHeight}px`;
    }
  }
}
