// This file monkey-patches Turbo to call morphdom instead of a manual replaceWith on the patched element,
// effectively allowing to trigger CSS-transitions and animations via remove updates.

import { nextAnimationFrame } from '../lib/timing';
import morphdom from 'morphdom';

const nativeReplaceWith = HTMLElement.prototype.replaceWith;
let morphEnabled = false;

function stringifyFragment(fragment: Node | string): string {
  if (typeof fragment === 'string') {
    return fragment;
  }
  const div = document.createElement('div');
  div.appendChild(fragment.cloneNode(true));
  return div.innerHTML;
}

document.addEventListener('turbo:before-stream-render', async () => {
  morphEnabled = true;
  await nextAnimationFrame();
  // turbo renders after exactly one animation frame
  await nextAnimationFrame();
  morphEnabled = false;
});

function replaceWith(...args: Array<Node | string>): void {
  // @ts-expect-error monkey-patch
  const context = this as HTMLElement;
  if (morphEnabled) {
    morphdom(context, stringifyFragment(args[0]));
  } else {
    nativeReplaceWith.bind(context)(...args);
  }
}

HTMLElement.prototype.replaceWith = replaceWith;
