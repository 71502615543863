import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect(): void {
    const dataset = (this.element as HTMLElement).dataset;
    const data = JSON.parse(dataset.analyticsData ?? '{}');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).gtag('event', dataset.analyticsEvent, data);
  }
}
