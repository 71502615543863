import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';
import { currentLocale } from '@/lib/locale';

function disableWeekends(date: Date): boolean {
  return date.getDay() === 0 || date.getDay() === 6;
}

export default class extends Controller {
  connect(): void {
    const input = this.element as HTMLInputElement;
    const locale = { firstDayOfWeek: 1 };
    if (currentLocale() === 'de') {
      Object.assign(locale, German);
    }
    flatpickr(input, {
      altInput: true,
      disable: [disableWeekends],
      minDate: input.min,
      maxDate: input.max,
      locale,
      altFormat: currentLocale() === 'de' ? 'd.m.Y' : 'd/m/Y',
    });
  }
}
