import { Application } from '@hotwired/stimulus';
import '@hotwired/turbo-rails';
import { registerControllers } from 'stimulus-vite-helpers';
import { startErrorTracking } from '../lib/errortracking';
import { start, ui } from '@nerdgeschoss/shimmer';

startErrorTracking();

import '../sprinkles/turbo';
import '../styles/application.scss';

const application = Application.start();
const controllers = import.meta.glob('../controllers/**/*_controller.ts', {
  eager: true,
});
registerControllers(application, controllers);

// @ts-expect-error the types aren't matching because of an explicit dependency in the shimmer module - but it is working anway as it's only type information
start({ application });

ui.consent.enableGoogleAnalytics('G-4K1ZGPD4V4');
