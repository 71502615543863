import { Controller } from '@hotwired/stimulus';
import { camera } from '@/lib/camera';

export default class extends Controller {
  static targets = ['fileInput'];
  fileInputTarget!: HTMLInputElement;

  async scan(): Promise<void> {
    this.element.classList.add('vinylscanner--animation');
    const file = await camera.captureImage();
    camera.stopVideo(); // Stop the video stream after capturing the image
    const files = new DataTransfer();
    files.items.add(file);
    this.fileInputTarget.files = files.files;
    this.fileInputTarget.form?.requestSubmit();

    setTimeout(() => {
      this.element.classList.remove('vinylscanner--animation');
      this.element.classList.add('vinylscanner--scanning');
    }, 10000);
  }
}
