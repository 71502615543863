import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash/debounce';
import { loadHTML } from '@/lib/ajax';
import { nextAnimationFrame } from '@/lib/timing';
import { isDesktop } from '@/lib/viewport';

class Base extends Controller {
  public resultsTarget!: HTMLDivElement;
  public wrapperTarget!: HTMLDivElement;
}

export default class extends (Controller as typeof Base) {
  static targets = ['results', 'wrapper'];
  private debounceTriggerSearch = debounce(this.triggerSearch, 200);

  connect(): void {
    this.wrapperTarget.classList.add('offers-list--empty');
  }

  type(event: Event): void {
    this.debounceTriggerSearch(event);
  }

  preventEnter(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  resetFloatingElements(): void {
    if (isDesktop()) {
      const floatingElements = document.querySelectorAll('.out-of-viewport');
      floatingElements.forEach((element) => {
        element.classList.remove('out-of-viewport');
        element.setAttribute('style', '');
      });
    }
  }

  private async triggerSearch(event: Event): Promise<void> {
    const form = (event.target as HTMLInputElement).form!;
    const s = form['catalog_search[s]']?.value;
    const media = form['catalog_search[media_type]']?.value;
    const image = (form['catalog_search[image]'] as HTMLInputElement)
      ?.files?.[0];

    if (s === '' && !image) {
      this.resetFloatingElements();
      this.resultsTarget.innerHTML = '';
      this.wrapperTarget.classList.add('offers-list--empty');
      this.scrollToResults();
      return;
    }
    const searchParams: Record<string, unknown> = { s, media };
    if (image) {
      searchParams['image'] = image;
    }
    const html = await loadHTML(form.action, searchParams, 'POST');

    if (form['catalog_search[s]'].value !== s) {
      return;
    }
    this.resultsTarget.innerHTML = html;
    this.wrapperTarget.classList.remove('offers-list--empty');
    this.scrollToResults();
  }

  private async scrollToResults(): Promise<void> {
    const offset = (isDesktop() ? 110 : 44) + 96;
    const top =
      this.resultsTarget.getBoundingClientRect().top -
      offset +
      document.documentElement.scrollTop;
    await nextAnimationFrame();
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }
}
