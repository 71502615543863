import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  async connect(): Promise<void> {
    const element = this.element as HTMLElement;
    element.classList.add(this.checkTouch() ? 'touch' : 'no-touch');
    element.classList.add((await this.checkWebP()) ? 'webp' : 'no-webp');
  }

  private async checkWebP(): Promise<boolean> {
    return new Promise((res) => {
      const webP = new Image();
      webP.src =
        'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
      webP.onload = webP.onerror = () => {
        res(webP.height === 2);
      };
    });
  }

  private checkTouch(): boolean {
    return window.ontouchstart !== undefined;
  }
}
