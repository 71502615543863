import { trackError } from '@/lib/errortracking';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect(): void {
    (this.element as HTMLImageElement).onerror = () => {
      trackError(new Error('Failed to load image.'), {
        src: (this.element as HTMLImageElement).src,
      });
    };
  }
}
