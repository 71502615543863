export async function loadHTML(
  path: string,
  params: Record<string, unknown> = {},
  method: 'GET' | 'POST' = 'GET'
): Promise<string> {
  let url = path;
  if (method === 'GET') {
    url = [path, serializeParams(params)].filter(Boolean).join('?');
  }
  const headers: {
    [key: string]: string;
  } = {
    'X-Requested-With': 'XMLHttpRequest',
  };
  if (method === 'POST') {
    headers['X-CSRF-Token'] = (
      document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement
    )?.content;
  }
  const body = method === 'POST' ? serializeFormData(params) : undefined;
  const data = await fetch(url, {
    credentials: 'include',
    method,
    headers,
    body,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Response Error ${response.status}`);
    }
    return response.text();
  });
  return data;
}

function serializeFormData(params: Record<string, unknown>): FormData {
  const data = new FormData();
  Object.keys(params).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.set(key, params[key] as any);
  });
  return data;
}

function serializeParams(params: Record<string, unknown>): string {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    urlParams.set(key, String(params[key]));
  });
  return urlParams.toString();
}
