import { Controller } from '@hotwired/stimulus';
import { loadHTML } from '@/lib/ajax';

export default class extends Controller {
  static values = {
    url: String,
  };
  declare urlValue: string;

  async load(): Promise<void> {
    const html = await loadHTML(this.urlValue);
    (this.element as HTMLDivElement).insertAdjacentHTML('beforebegin', html);
    this.element.remove();
  }
}
