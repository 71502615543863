import $ from 'jquery';
import 'slick-carousel';

import { Controller } from '@hotwired/stimulus';

class Base extends Controller {
  prevTarget!: HTMLElement;
  nextTarget!: HTMLElement;
  contentTarget!: HTMLElement;
}

export default class extends (Controller as typeof Base) {
  static targets = ['prev', 'next', 'content'];

  connect(): void {
    $(this.contentTarget).slick({
      prevArrow: this.prevTarget,
      nextArrow: this.nextTarget,
      dots: true,
      appendDots: this.element,
      // autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
      slidesToShow: 3,
      centerMode: true,
      centerPadding: 'calc(50vw - 620px - 16px)',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '18px',
            slidesToShow: 1,
          },
        },
      ],
    });
  }

  disconnect(): void {
    $(this.contentTarget).slick('unslick');
  }
}
