import * as Sentry from '@sentry/browser';

declare const process: { env: Record<string, string> };

export function startErrorTracking(): void {
  Sentry.init({
    dsn: 'https://3ffd2295ef7d42f9a519357d964e6c7d@o385233.ingest.sentry.io/5660181',
    environment: process.env.RAILS_ENV,
  });
}

export function trackError(
  error: Error,
  extra: Record<string, string> = {}
): void {
  Sentry.captureException(error, { extra });
}
